<template>
  <v-card
    outlined
    data-cy="order-summary-card"
  >
    <v-card-text>
      <h4 class="text-center">
        <span v-t="'productDetails.summary'" />
      </h4>

      <div
        v-for="item in orderSummary.plugins"
        :key="item.id"
        class="card-item"
      >
        <div class="plugin-name">
          {{ item.name }}
        </div>
        <div class="plugin-price pl-4">
          {{ item.price }}
        </div>
      </div>

      <div
        v-for="item in orderSummary.additionalRows"
        :key="item.id"
        class="card-item"
      >
        <div class="plugin-name">
          {{ item.name }}
        </div>
        <div class="plugin-price pl-4">
          {{ item.price }}
        </div>
      </div>

      <div
        v-if="orderSummary.totalPrice"
        class="card-item font-weight-bold"
      >
        <div class="plugin-name">
          {{ $t('productDetails.totalPrice') }}
        </div>
        <div class="plugin-price pl-4">
          {{ orderSummary.totalPrice }}
        </div>
      </div>

      <div
        v-if="orderSummary.totalPriceWithVAT"
        class="d-flex flex-column"
      >
        <div
          class="d-flex flex-row font-weight-bold pa-2"
        >
          <div class="plugin-name">
            {{ $t('productDetails.totalPriceWithVat') }} {{ vat }}
          </div>
          <div class="plugin-price pl-4">
            {{ orderSummary.totalPriceWithVAT }}
          </div>
        </div>
        <div class="total-price-with-vat-info pa-2">
          <span>{{ $t('payment.euSubjectsWithoutValidVatId') }}</span>
          <br>
          <span>
            {{ $tc('payment.basedOnYourBillingInfoYouWillBeIssuedExtra_percents_VatCharge', vat) }}
          </span>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    clientId: {
      type: String,
      default: null
    },
    orderSummary: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('client', { clientGetter: 'clientById' }),
    vat () {
      const client = this.clientGetter(this.clientId || this.$route.params.clientId)
      return client && client.billingDetails && client.billingDetails.billing && client.billingDetails.billing.vat
        ? client.billingDetails.billing.vat
        : ''
    },
    plugins () {
      return this.orderSummary.plugins || []
    },
    additionalRows () {
      return this.orderSummary.additionalRows || []
    }
  }
}
</script>

<style lang="scss" scoped>
  .total-price-with-vat-info {
    font-size: 8pt;
    line-height: 12pt;
    font-weight: normal;
  }

  .plugin-name,
  .plugin-price {
    width: 50%;
  }

  .plugin-price {
    text-align: right;
  }

  .card-item {
    display: flex;
    flex-direction: row;
    padding: 8px;
    border-bottom: thin solid rgba(0, 0, 0, 0.12);

    &:last-child {
      border-bottom: none;
    }
  }
</style>
